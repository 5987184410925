<template>
  <div class="page1">
    <!--面包屑-->
    <div class="breadcrumb">
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <!--主体内容-->
    <div class="content">
      <h2 class="title"><span class="title_icon"></span>业务详情</h2>
      <div class="Wrapper">
        <div class="section-title">基础信息</div>
        <el-form label-position="right" label-width="140px" :model="formInline" ref="form">
          <el-form-item label="停车卡名称:">
            {{ formInline.parkCardTitle }}
          </el-form-item>
          <el-form-item label="车牌号码:">
            {{ formInline.plateListDesc }}
          </el-form-item>
          <el-form-item label="手机号:">
            {{ formInline.phoneNumber }}
          </el-form-item>
          <el-form-item label="备注:">
            {{ formInline.remark }}
          </el-form-item>
          <div class="section-title">销售信息</div>
          <el-form-item label="有效期起始日:">
            {{ formInline.startDateDesc }}
          </el-form-item>
          <el-form-item label="有效期截止日:">
            {{ formInline.endDateDesc }}
          </el-form-item>
          <el-form-item label="订单总金额:">
            {{ (formInline.money / 100).toFixed(2) || "0.00" }}
          </el-form-item>
        </el-form>
        <div class="section-title">停车卡订单</div>
        <div class="tableWrapper bgFFF paddingB10">
          <el-table
            header-cell-class-name="header-call-style"
            border
            v-loading="loading"
            stripe
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column
              type="index"
              :label="$t('list.index')"
              width="70"
              align="center"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="item.prop == 'notes'"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              v-for="item in tableCols"
              :key="item.prop"
              :formatter="item.formatter"
              align="center"
            ></el-table-column>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper" v-show="total > 0">
            <div class="block">
              <el-pagination
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public";
export default {
  name: "parkCardNewAdd",
  data() {
    return {
      // expirationData:'',
      parkCardIsActive: true,
      isNowDate: true,
      ruleStartTime: "",
      ruleList: [],
      isEdit: false,
      loading: false,
      scopeClick: "",
      scopeDesc: "",
      operationId: "",
      formInline: {
        parkCardRuleId: "",
        numberRuleActivities: [
          {
            numberId: "",
            plateNumber: "",
            plateColor: "",
          },
        ],
        phoneNumber: "",
        useRangeDesc: "",
        startDate: new Date().getTime(),
        totalMoney: "",
        desc: "",
      },
      colorList: [],
      tableData: [],
      tableCols: [
        {
          prop: "payTime",
          label: this.$t("list.payment_time"),
          width: "",
          formatter: (r, c, v) => {
            return dateFormat(new Date(Number(v)), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          prop: "buyCycle",
          label: "购买周期数",
          width: "",
        },
        {
          prop: "startDate",
          label: "有效期起始日",
          width: "",
          formatter: (r, c, v) => {
            return dateFormat(new Date(Number(v)), "yyyy-MM-dd HH:mm:ss");
          },
        },
        {
          prop: "endDate",
          label: "有效期截止日",
          width: "",
          formatter: (r, c, v) => {
            return dateFormat(new Date(Number(v)), "yyyy-MM-dd HH:mm:ss");
          },
        },

        {
          prop: "money",
          label: this.$t("list.Pay_amount"),
          width: "",
          formatter: (r, c, v) => {
            return v ? v / 100 : "0";
          },
        },
        {
          prop: "buyTypeDesc",
          label: "购买渠道",
          width: "",
        },
        {
          prop: "tradeNo",
          label: "渠道流水号",
          width: "",
        },
        {
          prop: "remark",
          label: "备注",
          width: "",
        },
      ],
      total: 0,
      page: 0,
      pageSize: 15,
    };
  },
  methods: {
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    parkCardRuleIdChange() {
      let parksName = [];
      let parks = null;
      this.formInline.numberRuleActivities.splice(1);
      this.ruleList.forEach((v) => {
        if (v.parkCardRuleId == this.formInline.parkCardRuleId) {
          if (v.scope == 1 && v.scopeDesc === "全部车场") {
            this.getScopes(v.operatorId);
          }
          parks = v.parks;
          this.formInline.validityPeriod = v.validityPeriod;
          // this.ruleStartTime = v.effectStartTime;
          this.scopeClick = v.scope;
          this.scopeDesc = v.scopeDesc;
          this.operationId = v.operationId;
          this.formInline.carMaxNum = v.carMaxNum;
          this.formInline.effectType = v.effectType;
          this.formInline.effectDaysAfterBuy = v.effectDaysAfterBuy;
          this.formInline.effectStartDate = this.$moment(parseInt(v.effectStartTime)).format(
            "YYYY-MM-DD"
          );
          this.formInline.effectEndDate = this.$moment(parseInt(v.effectEndTime)).format(
            "YYYY-MM-DD"
          );
        }
      });
      if (this.scopeClick == 2) {
        parks.forEach((v) => {
          parksName.push(v.parkName);
          this.formInline.useRangeDesc = parksName.toString();
        });
      } else {
        // 全部车场
        // parksName.push(this.scopeDesc);
        // this.getScopes(this.operatorId);
      }
    },

    // 获取全部车场名称
    getScopes(operationId) {
      const opt = {
        url: "/acb/2.0/park/currentUserParkList",
        method: "get",
        data: {
          operationId: operationId,
          dataSource: 0,
        },
        success: (res) => {
          let arr = [];
          res.value.forEach((item) => {
            arr.push(item.parkName);
          });
          this.formInline.useRangeDesc = arr.join("、");
        },
      };
      this.$request(opt);
    },
    getColor() {
      this.$axios.get("/acb/2.0/specialplate/plateColor/list").then((res) => {
        if (res.state == 0) {
          this.colorList = res.value;
        } else {
          this.$alert(res.desc, "提示");
        }
      });
    },
    getDetail() {
      this.$axios
        .get(`/acb/2.0/parkCard/detail`, {
          data: {
            parkCardId: this.$route.query.parkCardId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline = res.value;
            console.log(this.formInline, "this.formInline-12031023-");
            let createdTime = this.formInline.createdTime.substring(0, 10);
            // 车牌号
            let carList = [];
            this.formInline.plateList.forEach((item) => {
              item.plateNumber = item.plateColor + ":" + item.plateNumber;
              carList.push(item.plateNumber);
            });
            this.formInline.plateListDesc = carList.join(",");
            let newDate = dateFormat(new Date(), "yyyy-MM-dd");
            this.isNowDate = createdTime === newDate;
            // 查看停车卡是否已生效
            this.parkCardIsActive = this.formInline.state == 1;
            this.formInline.plateColor = res.value.plateColorCode;
            // this.parkCardRuleIdChange(this.formInline.parkCardRuleId);
          } else {
            this.$alert(res.desc);
          }
        });
    },
    // 获取停车卡名称列表
    getRulelist() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get("/acb/2.0/parkCardRule/list", {
            data: {
              appSale: 0,
              states: 2,
              page: 0,
              pageSize: 0,
            },
          })
          .then((res) => {
            let ruleList = res && res.value.list;
            if (this.isEdit) {
              let flag = false;
              for (let i = 0; i < ruleList.length; i++) {
                if (ruleList[i].parkCardRuleId == this.$route.query.parkCardRuleId) {
                  flag = true;
                }
              }
              setTimeout(() => {
                if (!flag) {
                  ruleList.push({
                    parkCardRuleId: this.$route.query.parkCardRuleId,
                    name: this.$route.query.parkCardTitle,
                  });
                }
              }, 100);
            }
            this.ruleList = ruleList;
            resolve();
          });
      });
    },
    addEdit() {
      let url = this.isEdit ? "/acb/2.0/parkCard/updateNew" : "/acb/2.0/parkCard/add";
      let plateArr = [];
      this.formInline.numberRuleActivities.forEach((val) => {
        plateArr.push({
          plateColor: val.plateColor,
          plateNumber: val.numberName + val.plateNumber,
        });
      });
      const data = {
        scope: this.formInline.scope,
        // plateNumber: this.formInline.plateNumber,
        // plateColor: this.formInline.plateColor,
        plateList: plateArr,
        phoneNumber: this.formInline.phoneNumber,
        parkCardRuleId: this.formInline.parkCardRuleId,
      };
      if (this.isEdit) {
        data.parkCardId = this.$route.query.parkCardId;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          let find = false;
          let arr = this.formInline.numberRuleActivities;
          for (var i = 0; i < arr.length; i++) {
            for (var j = i + 1; j < arr.length; j++) {
              if (
                arr[i].plateColor == arr[j].plateColor &&
                arr[i].numberName == arr[j].numberName &&
                arr[i].plateNumber == arr[j].plateNumber
              ) {
                find = true;
                break;
              }
            }
            if (find) break;
          }
          if (find) {
            this.$message({
              type: "warning",
              message: "输入了相同的车牌",
            });
            return;
          }
          let method = this.isEdit ? "post" : "post";
          // this.$axios[method](url, {
          //   data,
          //   config: {
          //     headers: {
          //       'Content-Type': 'application/json;charset=UTF-8'
          //     }
          //   }
          // }).then(res => {
          //   if (res.state == 0) {
          //     this.$router.go(-1);
          //   } else {
          //     this.$alert(res.desc, '提示');
          //   }
          // });
          const opt = {
            url,
            method,
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            data,
            success: (res) => {
              if (res.state == 0) {
                this.$router.go(-1);
                this.$message({
                  type: "success",
                  message: res.desc,
                });
              } else {
                this.$alert(res.desc, "提示");
              }
            },
            fail: (err) => {},
          };
          this.$request(opt);
        }
      });
    },
    // 获取历史订单列表
    getNewOrder() {
      this.$axios
        .get(`/acb/2.0/parkCard/renewalOrder`, {
          data: {
            originalOrderId: this.$route.query.orderId,
          },
        })
        .then((res) => {
          this.tableData = res.value;
          // console.log(res, "res---------");
        });
    },
  },
  computed: {
    expirationData() {
      console.log(this.formInline.startDate, this.formInline.expirationDate, "0000000000");
      let data =
        this.formInline.startDate +
        (this.formInline.expirationDate || 0) * 30 * 24 * 60 * 60 * 1000;
      let data1 = dateFormat(new Date(data), "yyyy-MM-dd");
      return data1;
    },
  },
  components: {},
  created() {
    console.log(this.$route.query, "this.$route.query-------");
    // 获取车牌颜色
    this.getColor();
    // 获取停车卡名称列表
    this.getRulelist().then(() => {
      if (this.$route.query.parkCardId) {
        this.isEdit = true;
        this.getDetail();
      }
    });
    if (this.$route.query.orderId) {
      this.getNewOrder();
    }
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
}
.breadcrumb {
  text-align: right;
  margin-bottom: 16px;
}
.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .range-wrapper {
    line-height: 20px;
    padding: 10px;
    background: #ecf5ff;
    margin-top: 10px;
    border-radius: 8px;
    min-width: 300px;
  }

  .button-style {
    padding: 4px 10px;
    margin-left: 5px;
  }

  .icon-style {
    font-size: 30px;
    vertical-align: middle;
  }
}

.section-title {
  line-height: 35px;
  background-color: #ccc;
  color: #656565;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 20px;
}
</style>
